import {rgb} from 'pdf-lib'

export class PdfGeneratorService {
    // Static variable to cache the image bytes
    static routaLogoBytes = null

    constructor() {
        this.defaultVerticalMargin = 60
        this.defaultHorizontalMargin = 40
        this.defaultInnerHorizontalMargin = 10
        this.footerZone = 30 + this.defaultVerticalMargin
    }

    /**
     * @param {PDFDocument} pdfDoc PDF document object
     * @param {string} text Text to add
     * @param {PDFFont} font Font object
     * @param {number} fontSize Font size
     * @param {number} pageY Y position to start drawing the title
     * @param {number} verticalMargin Vertical margin from the top of the page
     * @param {number} horizontalMargin Horizontal margin from the left of the page
     * @param {number} assumedContentZone Assumed min height of the content zone
     * @param {PDFImage} footerImage Footer image object
     * @returns {number} Y position after drawing the title
     */
    addText(pdfDoc, text, font, fontSize, pageY = undefined, verticalMargin = this.defaultVerticalMargin, horizontalMargin = this.defaultHorizontalMargin, assumedContentZone = 70, footerImage) {
        const pages = pdfDoc.getPages()
        let page = pages[pages.length - 1]
        let y = pageY || page.getHeight() - verticalMargin

        // Start new page if content doesn't fit to current page
        const contentFit = () => {
            if (y && y < this.footerZone + assumedContentZone) {
                page = pdfDoc.addPage([595, 842])
                y = page.getHeight() - verticalMargin
                this.addRoutaLogoToFooter(pdfDoc, page, footerImage)
            }
        }
        contentFit()

        const width = page.getWidth() - 2 * horizontalMargin
        const x = horizontalMargin


        const lines = this.getWrappedText(font, text.toString(), fontSize, width)
        lines.forEach((lineText) => {
            page.drawText(lineText, {
                x: x,
                y: y,
                size: fontSize,
                font: font,
                color: rgb(0, 0, 0),
            })

            y -= fontSize + 2
            contentFit()
        })

        return y - fontSize
    }

    /**
     * @param {Array} tableData Multi-dimensional array containing the table data
     * @param {PDFDocument} pdfDoc PDF document object
     * @param fontStyles
     * @param {number} pageY Y position to start drawing the table
     * @param {number} verticalMargin Vertical margin from the top of the page
     * @param {number} horizontalMargin Horizontal margin from the left of the page
     * @param {PDFImage} footerImage object
     * @param itemsPerRow
     * @param index
     * @param rowPadding
     */
    // eslint-disable-next-line
    addVerticalTable(tableData, pdfDoc, fontStyles, pageY = undefined, verticalMargin = this.defaultVerticalMargin, horizontalMargin = this.defaultHorizontalMargin, footerImage, itemsPerRow = 1, index = 1, rowPadding = 10) {
        const pages = pdfDoc.getPages()
        let page = pages[pages.length - 1]

        const componentStart = this.getComponentStart(page, itemsPerRow, index)
        const componentEnd = this.getComponentEnd(page, itemsPerRow, index)

        // Table styling and layout
        const tableWidth = this.getComponentLength(page, itemsPerRow)
        const numColumns = 2
        const columnWidth = tableWidth / numColumns
        const font = fontStyles.textFont
        const headerFont = fontStyles.headerFont || font
        const defaultFontSize = fontStyles.textFontSize || 12
        const defaultHeaderFontSize = fontStyles.headerFontSize || defaultFontSize
        const textColor = rgb(0, 0, 0)

        // Y position to start drawing the table
        let y = pageY || page.getHeight() - verticalMargin

        const addText = (lineText, x, y, textFont, fontSize) => {
            page.drawText(lineText, {
                x: x,
                y: y,
                size: fontSize,
                font: textFont,
                color: textColor,
            })
        }

        for (const row of tableData) {
            let x = componentStart
            let rowHeight = 20

            // Check that the text fit to the current page
            if (y - rowHeight < this.footerZone) {
                page = pdfDoc.addPage([595, 842])
                this.addRoutaLogoToFooter(pdfDoc, page, footerImage)
                y = page.getHeight() - verticalMargin
            }

            // Row should only have two columns, "header" and "value"
            row.forEach((cellValue, index) => {
                let cellText = cellValue !== null && cellValue !== undefined ? cellValue.toString() : '-'
                const lines = this.getWrappedText(font, cellText, defaultFontSize, columnWidth)
                rowHeight = Math.max(rowHeight, lines.length * (defaultFontSize + 2) + 2 * rowPadding)

                let textY = y - rowPadding
                lines.forEach((lineText) => {
                    const lineFontSize = index === 0 ? defaultHeaderFontSize : defaultFontSize
                    const lineFont = index === 0 ? headerFont : font
                    addText(lineText, x + 5, textY - defaultFontSize, lineFont, lineFontSize)
                    textY -= defaultFontSize + 2
                })

                x += columnWidth
            })

            // Draw a horizontal line at the end of the row
            page.drawLine({
                start: {x: componentStart, y: y - rowHeight},
                end: {x: componentEnd, y: y - rowHeight},
                thickness: 1,
                color: textColor,
            })

            y -= rowHeight
        }

        return y
    }



    addHorizontalLine(pdfDoc, pageY = undefined, verticalMargin = this.defaultVerticalMargin, footerImage, itemsPerRow = 1, index = 1) {
        const pages = pdfDoc.getPages()
        let page = pages[pages.length - 1]

        const componentStart = this.getComponentStart(page, itemsPerRow, index)
        const componentEnd = this.getComponentEnd(page, itemsPerRow, index)

        // Table styling and layout
        const textColor = rgb(0, 0, 0)

        // Y position to start drawing the table
        let y = pageY || page.getHeight() - verticalMargin

        // Draw a horizontal line at the end of the row
        page.drawLine({
            start: {x: componentStart, y: y},
            end: {x: componentEnd, y: y},
            thickness: 1,
            color: textColor,
        })

        y -= 10

        return y
    }

    /**
     * @param {Array} tableData Multi-dimensional array containing the table data
     * @param {PDFDocument} pdfDoc PDF document object
     * @param fontStyles
     * @param {number} pageY Y position to start drawing the table
     * @param {number} verticalMargin Vertical margin from the top of the page
     * @param {number} horizontalMargin Horizontal margin from the left of the page
     * @param {PDFImage} footerImage object
     * @param itemsPerRow Total number of components on line
     * @param index A number from 1 to `amount`
     */
    // eslint-disable-next-line
    addTable(tableData, pdfDoc, fontStyles, pageY = undefined, verticalMargin = this.defaultVerticalMargin,
             horizontalMargin = this.defaultHorizontalMargin, footerImage, itemsPerRow = 1, index = 1, landscape = false) {
        const pages = pdfDoc.getPages()
        let page = pages[pages.length - 1]

        // Table styling and layout
        let componentStart = this.getComponentStart(page, itemsPerRow, index)
        let componentEnd = this.getComponentEnd(page, itemsPerRow, index)

        const tableWidth = this.getComponentLength(page, itemsPerRow)
        const numColumns = tableData.length > 1 ? tableData[1].length : tableData[0].length // Header can be empty
        const columnWidth = tableWidth / numColumns
        const rowPadding = 5 // Padding above and below each row
        const font = fontStyles.textFont
        const headerFont = fontStyles.headerFont || font
        const defaultFontSize = fontStyles.textFontSize || 12
        const defaultHeaderFontSize = fontStyles.headerFontSize || defaultFontSize
        const textColor = rgb(0, 0, 0)

        // Y position to start drawing the table
        let y = pageY || page.getHeight() - verticalMargin

        // Slice the first row of the table data to get the headers
        const headerData = tableData.length > 0 ? tableData.slice(0, 1)[0] : []
        const bodyData = tableData.length > 1 ? tableData.slice(1) : []

        const addText = (lineText, x, y, textFont, fontSize) => {
            page.drawText(lineText, {
                x: x,
                y: y,
                size: fontSize,
                font: textFont,
                color: textColor,
            })
        }

        const addHeaders = (headers, font, x, y) => {
            let headerX = x
            let headerY = y
            let maxHeaderLines = 1

            headers.forEach((text) => {
                let headerText = text !== null && text !== undefined ? text.toString() : '-'
                const lines = this.getWrappedText(font, headerText, defaultFontSize, columnWidth)
                maxHeaderLines = Math.max(maxHeaderLines, lines.length)

                lines.forEach((lineText, lineIndex) => {
                    addText(lineText, headerX + 5, headerY - (defaultFontSize + 2) * lineIndex - 5, headerFont, defaultHeaderFontSize)
                })
                headerX += columnWidth
            })

            // Adjust Y position after drawing the headers based on the tallest header cell
            return y - (maxHeaderLines * (defaultFontSize + 2)) - 10
        }

        // Draw headers on the first page
        if (headerData.length > 0) {
            y = addHeaders(headerData, font, componentStart, y)
        }
        for (const row of bodyData) {
            let x = componentStart
            let rowHeight = 20

            // Check that the text fit to the current page
            if (y - rowHeight < this.footerZone) {
                page = landscape ? pdfDoc.addPage([842, 595]) : pdfDoc.addPage()
                this.addRoutaLogoToFooter(pdfDoc, page, footerImage)
                y = page.getHeight() - verticalMargin
                if (headerData.length > 0) {
                    y = addHeaders(headerData, font, horizontalMargin, y)
                }
            }

            for (const cellValue of row) {
                let cellText = cellValue !== null && cellValue !== undefined ? cellValue.toString() : '-'
                const lines = this.getWrappedText(font, cellText, defaultFontSize, columnWidth)
                rowHeight = Math.max(rowHeight, lines.length * (defaultFontSize + 2) + 2 * rowPadding)

                let textY = y - rowPadding
                lines.forEach((lineText) => {
                    addText(lineText, x + 5, textY - defaultFontSize, font, defaultFontSize)
                    textY -= defaultFontSize + 2
                })

                x += columnWidth
            }

            // Draw a horizontal line at the end of the row
            page.drawLine({
                start: {x: componentStart, y: y - rowHeight},
                end: {x: componentEnd, y: y - rowHeight},
                thickness: 1,
                color: textColor,
            })

            y -= rowHeight
        }

        return y
    }

    /*
    * Calculate text width and wrap text
    * @param {PDFFont} font Font object
    * @param {string} text Text to wrap
    * @param {number} fontSize Font size
    * @param {number} maxWidth Maximum width of the text
    * @returns {Array} Array of wrapped text lines
    */
    getWrappedText(font, text, fontSize, maxWidth) {
        text = text.replaceAll(/\n/g, ' ')
        text = text.replaceAll(/\r/g, ' ')
        const words = text.split(' ')
        let lines = []
        let line = ''

        // Split the text into lines that fit within the column width
        for (const word of words) {
            const testLine = line + word + ' '

            // eslint-disable-next-line
            console.log(testLine)

            const textWidth = font.widthOfTextAtSize(testLine, fontSize)
            if (textWidth > maxWidth - 10) { // If the word does not fit
                if (line.length > 0) {
                    lines.push(line.trim()) // Add the current line to the lines array
                }
                line = word + ' ' // Start a new line with the current word
            } else {
                line = testLine // Continue the current line with the word
            }
        }

        // Handle single long words without spaces
        if (line.length > 0) {
            const wordWidth = font.widthOfTextAtSize(line, fontSize)
            if (wordWidth > maxWidth - 10) {
                // Word is too long, break it into smaller chunks
                let start = 0
                while (start < line.length) {
                    let end = start + Math.floor(maxWidth / font.widthOfTextAtSize('A', fontSize))
                    lines.push(line.slice(start, end))
                    start = end
                }
            } else {
                lines.push(line.trim())
            }
        }

        return lines
    }

    static async cacheRoutaFooterLogo() {
        // Check if we already have the image bytes cached
        if (!PdfGeneratorService.routaLogoBytes) {
            const imagePath = require('@/assets/routa_dark.jpg')
            // Fetch image bytes and store in the static variable if not already cached
            // We can also use browser cache to store the image bytes
            PdfGeneratorService.routaLogoBytes = await fetch(imagePath)
                .then((res) => res.arrayBuffer())
                .catch(() => null)
        }
    }

    /**
     * @param {PDFDocument} pdfDoc document object
     * @param {ArrayBuffer} footerImageBytes Image bytes for the footer logo
     * @returns {PDFImage} Image object
     */
    static async embedImageToDoc(pdfDoc, footerImageBytes) {
        if (!footerImageBytes) {
            return
        }

        const image = await pdfDoc.embedJpg(footerImageBytes)
        return image
    }

    /**
     *
     * @param {PDFDocument} pdfDoc PDF document object
     * @param {ArrayBuffer} fontBytes Font bytes
     * @returns {PDFFont} Font object
     */
    async embedFontToDoc(pdfDoc, fontBytes) { //
        const font = await pdfDoc.embedFont(fontBytes)
        return font
    }

    /**
     * @param {PDFDocument} pdfDoc document object
     * @param page
     * @param {PDFImage} embededImage Image object
     */
    addRoutaLogoToFooter(pdfDoc, page = null, embededImage) {
        if (!embededImage) {
            return
        }

        let currenPage = page
        if (!currenPage) {
            const pages = pdfDoc.getPages()
            currenPage = pages[pages.length - 1]
        }

        const imageWidth = 50
        const imageHeight = (embededImage.height / embededImage.width) * imageWidth
        const pageWidth = page.getWidth()

        page.drawImage(embededImage, {
            x: pageWidth - imageWidth - 50, // Align to the right with a margin
            y: 30,
            width: imageWidth,
            height: imageHeight,
        })
    }


    /**
     * @param {PDFDocument} pdfDoc document object
     * @param page
     * @param embededImages
     * @param x
     * @param y
     * @param {PDFImage} footerImage object
     * @param itemsPerRow
     * @param index
     * @param imagesPerRow
     * @param verticalRowAmount
     */
    // eslint-disable-next-line
    addImages(pdfDoc, page = null, embededImages = [], x = null, y = null, footerImage, itemsPerRow = 1, index = 1, imagesPerRow = 2, verticalRowAmount = 3) {

        const defaultVerticalMargin = 10

        embededImages.forEach((image, i) => {
            let currenPage = page
            if (!currenPage) {
                const pages = pdfDoc.getPages()
                currenPage = pages[pages.length - 1]
            }

            const defaultHeight = this.getDefaultPageHeight(currenPage)

            const requiredImageHeight = (defaultHeight / verticalRowAmount) - defaultVerticalMargin * verticalRowAmount

            const maxWidth = this.getComponentLength(currenPage, itemsPerRow)

            const imageSize = this.scaleImageWidth(maxWidth, imagesPerRow)


            const jpgDims = image.scaleToFit(imageSize, requiredImageHeight)

            if (this.isPartOfSequence(i, imagesPerRow)) {
                y -= (requiredImageHeight + defaultVerticalMargin)
            }

            if (y - requiredImageHeight < this.footerZone) {
                page = pdfDoc.addPage([595, 842])
                this.addRoutaLogoToFooter(pdfDoc, page, footerImage)
                currenPage = page
                y = page.getHeight() - this.defaultVerticalMargin - requiredImageHeight - defaultVerticalMargin
            }

            const start = this.getComponentStart(currenPage, itemsPerRow, index)

            currenPage.drawImage(image, {
                x: this.getForImageXPosition(maxWidth, i, imagesPerRow, imageSize, start),
                y: y,
                width: jpgDims.width,
                height: jpgDims.height,
            })
        })
        return y
    }




    /**
     * @param {PDFDocument} pdfDoc document object
     * @param page
     * @param embededImage
     * @param x
     * @param y
     * @param {PDFImage} footerImage object
     * @param lessY
     * @param itemPerRow
     * @param index
     */
    // eslint-disable-next-line
    addImage(pdfDoc, page = null, embededImage, x = null, y = null, footerImage, lessY = 0, itemPerRow = 1, index = 1) {

        let currenPage = page
        if (!currenPage) {
            const pages = pdfDoc.getPages()
            currenPage = pages[pages.length - 1]
        }

        let width = this.getComponentLength(currenPage, itemPerRow)

        const jpgDims = embededImage.scaleToFit(width, width - lessY)

        if (y - jpgDims.height < this.footerZone) {
            page = pdfDoc.addPage([595, 842])
            this.addRoutaLogoToFooter(pdfDoc, page, footerImage)

            currenPage = page
            y = page.getHeight() - this.defaultVerticalMargin

        }

        if (!x) {
            x = this.getComponentStart(currenPage, itemPerRow, index)
        }

        y -= jpgDims.height


        currenPage.drawImage(embededImage, {
            x: x,
            y: y,
            width: jpgDims.width,
            height: jpgDims.height,
        })

        return y
    }

    getForImageXPosition(width, index, lineAmount, imageWidth, start) {
        const imageWidths = width / lineAmount;
        const column = index % lineAmount;
        return start + column * imageWidths;
    }

    getComponentLength(page, amount) {
        const pageWidth = page.getWidth()
        const outerMargin = this.defaultHorizontalMargin;
        const innerMargin = this.defaultInnerHorizontalMargin
        return ((pageWidth - outerMargin * 2) - innerMargin * amount -1) / amount;
    }

    getComponentEnd(page, amount, index) {
        const outerMargin = this.defaultHorizontalMargin;
        const innerMargin = this.defaultInnerHorizontalMargin
        const componentWidth = this.getComponentLength(page, amount)
        return outerMargin + (componentWidth * index) + innerMargin * (index - 1)
    }

    getComponentStart(page, amount, index) {
        const outerMargin = this.defaultHorizontalMargin;
        const innerMargin = this.defaultInnerHorizontalMargin
        const componentWidth = this.getComponentLength(page, amount)
        return outerMargin + (componentWidth * (index - 1)) + innerMargin * (index - 1)
    }

    scaleImageWidth(availableWidth, amount, imageMargin = 10) {
        const totalMargin = (amount - 1) * imageMargin;
        return (availableWidth - totalMargin) / amount;
    }

    isPartOfSequence(number, sequence) {
        return number % sequence === 0;
    }

    getBlankPageHeight(page) {
        return page.getHeight() - this.defaultVerticalMargin
    }

    getDefaultPageHeight(page) {
        return page.getHeight() - this.defaultVerticalMargin - this.footerZone
    }

    getAvailablePageHeight(page, currentY) {
        return page.getHeight() - this.footerZone - currentY
    }

}
