<template>
    <div class="p-2">
        <div
            class="data-list-item add"
            @click.stop="$emit('add')"
        >
            <div class="item-img-cont add-title">
                <i class="fa fa-plus"/>
            </div>
            <div class="item-text-cont">
            <span class="item-title-add">
              {{ $t('common.add_new') }}
            </span>
                <font-awesome-icon
                    icon="eye"
                    class="card-body-icon"
                />
            </div>
        </div>
        <div v-if="results && results.length" class="col-sm-12 nopads">
            <transition name="fade">
                <div
                    class="col-sm-12 pb-2 result-table"
                >
                    <b-table
                        :small="true"
                        :responsive="true"
                        :items="tableItems"
                        :fields="fields"
                        :bordered="true"
                        hover
                        class="pointer"
                        @row-clicked="onRowClicked"
                    >
                        <template slot="empty">
                            <div class="text-center my-2">
                                {{ $t('area_report.no_results') }}
                            </div>
                        </template>
                    </b-table>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ObservationTypeList',
    props: {
        results: {
            type: Array,
            default: function () {
                return []
            }
        }
    },
    data() {
        return {
            tableItems: [],
            fields: [
                {key: 'id', label: this.$t('observations.id'), sortable: true},
                {key: 'name', label: this.$t('observations.type'), sortable: true},
                {key: 'group', label: this.$t('observations.group'), sortable: true},
                {key: 'geomType', label: this.$t('observations.geometry_type'), sortable: true}
            ]
        }
    },
    mounted() {
        this.initTableItems()
    },
    methods: {
        initTableItems() {
            this.results.forEach(item => {
                this.tableItems.push({
                    id: item.id,
                    name: item.name,
                    group: item.observation_group.name,
                    geomType: item.geometry_type === 1 ? this.$t('observations.point') : this.$t('observations.line')
                })
            })
        },
        onRowClicked(item) {
            let ot = this.results.find(o => o.id === item.id)
            this.$emit('edit', ot)
        }
    }
}
</script>
